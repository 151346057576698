<template>
    <div class="p-persona-profile p-persona-profile--client-view">
        <div class="loading-state">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="mobile-navbar bg-dark align-items-center">
            <a :href="`/share/${this.$route.params.clientProjectNamePersonaPage}`" class="mobile-navbar__persona d-flex flex-column align-items-center">
                <div class="mobile-navbar__back-btn">
                    <inline-svg :src="require(`../assets/svg/icon-arrow-left.svg`)" />
                </div>
                <span>Back</span>
            </a>
            <a v-for="persona in personasFromProject" :key="persona.id" :href="`/share/${this.$route.params.clientProjectNamePersonaPage}/${persona.id}`" :class="`mobile-navbar__persona d-flex flex-column align-items-center ${[ this.$route.params.clientPersonaId == persona.id ? 'active' : '' ]}`">
                <img :src="persona.thumbnail ? persona.thumbnail : 'https://via.placeholder.com/40x40'" alt="Persona thumbnail" />
                <span>{{ persona.name }}</span>
            </a>
        </div>
        <div class="container-xl">
            <div class="d-none d-md-block">
                <PageHeader></PageHeader>
            </div>
            <div class="row">
                <div class="col-md-2 col-lg-3 col-0">
                    <div class="side-elements side-elements--previous d-flex align-items-center justify-content-center">
                        <a v-if="personasFromProject[personaIndex - 1]" :href="`/share/${this.$route.params.clientProjectNamePersonaPage}/${personasFromProject[personaIndex - 1].id}`" class="previous-persona d-md-block d-none">
                            <div class="d-flex flex-column align-items-center">
                                <img :src="personasFromProject[personaIndex - 1].thumbnail ? personasFromProject[personaIndex - 1].thumbnail : 'https://via.placeholder.com/48x48'" alt="Persona thumbnail" class="side-elements__thumbnail" />
                                <span class="side-elements__text d-none d-xl-block">Previous persona:</span>
                                <span class="side-elements__name d-none d-xl-block">{{ personasFromProject[personaIndex - 1].name }}</span>
                                <inline-svg :src="require(`../assets/svg/icon-arrow-left.svg`)" />
                            </div>
                        </a>
                        <div id="scrollspy-toc" class="scrollspy scrollspy--desktop list-group">
                            <div class="scrollspy__title">Jump to</div>
                            <a class="list-group-item list-group-item-action active" href="#scrollspy-presentation">Presentation</a>
                            <a v-if="this.hasTags" class="list-group-item list-group-item-action" href="#scrollspy-demographics">Demographics</a>
                            <a v-if="this.quote" class="list-group-item list-group-item-action" href="#scrollspy-quote">Quote</a>
                            <a v-if="this.hasKeywords" class="list-group-item list-group-item-action" href="#scrollspy-keywords">Keywords</a>
                            <a v-if="this.story" class="list-group-item list-group-item-action" href="#scrollspy-story">Story</a>
                            <a v-if="this.typicalDay" class="list-group-item list-group-item-action" href="#scrollspy-typicalDay">Typical Day</a>
                            <a v-if="this.hasSliders" class="list-group-item list-group-item-action" href="#scrollspy-sliders">Characteristics</a>
                            <span v-for="(textComponent, index) in textComponents" :key="index">
                                <a v-if="textComponent.content" class="list-group-item list-group-item-action" :href="`#scrollspy-${textComponent.id}`">{{ textComponent.title }}</a>
                            </span>
                            <a v-if="this.imageTopLeft || this.imageBottomLeft || this.imageMain || this.imageBottom" class="list-group-item list-group-item-action" href="#scrollspy-moodboard">Moodboard</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 col-lg-7 col-12" >
                    <div v-for="persona in personas($route.params.clientPersonaId)" :key="persona.id">
                        <!-- Header (thumbnail + name + description) -->
                        <div id="scrollspy-presentation" class="p-persona-profile__section p-persona-profile__section--header">
                            <div class="p-persona-profile__thumbnail">
                                <div class="image-preview" :style="`background: url('${imageThumbnail}') no-repeat center center; background-size: cover;`"></div>
                            </div>
                            <div>
                                <h1>{{ name }}</h1>
                                <p >{{ description }}</p>
                            </div>
                        </div>
                        <!-- Section: tags -->
                        <div id="scrollspy-demographics" class="p-persona-profile__section p-persona-profile__section--keywords" :style="`${[ this.hasTags ? `display: block;` : `display: none;` ]}`">
                            <h3>Demographics</h3>
                            <div class="d-flex align-items-center flex-wrap">
                                <Badge v-for="(tag, index) in tags" :key="index" :index="index" type="tags" :text="tags[index].text" variant="light" :icon="tags[index].icon" />
                            </div>
                        </div>
                        <!-- Section: quote -->
                        <div id="scrollspy-quote" class="p-persona-profile__section p-persona-profile__section--quote" :style="`${[ this.quote == '' ? `display: none;` : `display: block;` ]}`">
                            <h4>{{ quote }}</h4>
                        </div>
                        <!-- Section: keywords -->
                        <div id="scrollspy-keywords" class="p-persona-profile__section p-persona-profile__section--keywords" :style="`${[ this.hasKeywords ? `display: block;` : `display: none;` ]}`">
                            <h3>Keywords</h3>
                            <div class="d-flex align-items-center flex-wrap">
                                <Badge v-for="(keyword, index) in keywords" :key="index" :index="index" type="keywords" :text="keyword" />
                            </div>
                        </div>
                        <!-- Section: persona story -->
                        <div id="scrollspy-story" class="p-persona-profile__section p-persona-profile__section--text flex-lg-row flex-column" v-if="persona.personaType === 'role-based'" :style="`${[ this.story == '' ? `display: none;` : `display: flex;` ]}`">
                            <IconThumbnail icon="icon-book" :text="`${name}'s story`" />
                            <div class="content">
                                <Markdown :source="story" />
                            </div>
                        </div>
                        <!-- Section: typical day -->
                        <div id="scrollspy-typicalDay" class="p-persona-profile__section p-persona-profile__section--text flex-lg-row flex-column" v-if="persona.personaType === 'goal-directed'" :style="`${[ persona.typicalDay == '' ? `display: none;` : `display: flex;` ]}`">
                            <IconThumbnail icon="icon-book" :text="`${name}'s typical day`" />
                            <div class="content">
                                <Markdown :source="typicalDay" />
                            </div>
                        </div>
                        <!-- Section: sliders -->
                        <div id="scrollspy-sliders" class="p-persona-profile__section p-persona-profile__section--text flex-lg-row flex-column" :style="`${[ this.hasSliders ? `display: flex;` : `display: none;` ]}`">
                            <IconThumbnail icon="icon-book" :text="`What ${name} is like`" />
                            <div class="content">
                                <Slider v-for="(slider, index) in sliders" :key="index" v-model:rangeValue="sliders[index].value" v-model:textLeftValue="sliders[index].textLeft" v-model:textRightValue="sliders[index].textRight" />
                            </div>
                        </div>
                        <!-- Section: Dynamic text components -->
                        <div v-for="(textComponent, index) in textComponents" :key="index" :id="`scrollspy-${textComponent.id}`" class="p-persona-profile__section p-persona-profile__section--text p-persona-profile__section--dynamic-component flex-lg-row flex-column" :style="`${[ textComponent.content == '' ? `display: none;` : `display: flex;` ]}`">
                            <div>
                                <IconThumbnail :icon="textComponent.icon" :text="textComponent.title" />
                            </div>
                            <div class="content">
                                <Markdown :source="textComponent.content" />
                            </div>
                        </div>

                        <!-- Section: images -->
                        <div id="scrollspy-moodboard" class="p-persona-profile__section p-persona-profile__section--images">
                            <div class="d-flex">
                                <div>
                                    <div class="image image--side" :style="`${[ this.imageTopLeft == '' ? `display: none;` : `display: block;` ]}`">
                                        <div class="image-preview" :style="`background: url('${imageTopLeft}') no-repeat center center; background-size: cover;`"></div>
                                    </div>
                                    <div class="image image--side" :style="`${[ this.imageBottomLeft == '' ? `display: none;` : `display: block;` ]}`">
                                        <div class="image-preview" :style="`background: url('${imageBottomLeft}') no-repeat center center; background-size: cover;`"></div>
                                    </div>
                                </div>
                                <div class="image image--main" :style="`${[ this.imageMain == '' ? `display: none;` : `display: block;` ]}`">
                                    <div class="image-preview" :style="`background: url('${imageMain}') no-repeat center center; background-size: cover;`"></div>
                                </div>
                            </div>
                            <div class="image image--bottom" :style="`${[ this.imageBottom == '' ? `display: none;` : `display: block;` ]}`">
                                <div class="image-preview" :style="`background: url('${imageBottom}') no-repeat center center; background-size: cover;`"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-lg-2 col-0">
                    <div class="side-elements side-elements--next d-md-flex d-none align-items-center justify-content-center">
                        <a v-if="personasFromProject[personaIndex + 1]" :href="`/share/${this.$route.params.clientProjectNamePersonaPage}/${personasFromProject[ personaIndex + 1].id}`" class="next-persona">
                            <div @click="nextPersona" class="d-flex flex-column align-items-center">
                                <img :src="personasFromProject[personaIndex + 1].thumbnail ? personasFromProject[ personaIndex + 1 ].thumbnail : 'https://via.placeholder.com/48x48'" alt="Persona thumbnail" class="side-elements__thumbnail" />
                                <span class="side-elements__text d-none d-xl-block">Next persona:</span>
                                <span class="side-elements__name d-none d-xl-block">{{ personasFromProject[ personaIndex + 1 ].name }}</span>
                                <inline-svg :src="require(`../assets/svg/icon-arrow-right.svg`)" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="toc-mobile__open-btn align-items-center justify-content-center" @click="openTocMobile">
                <inline-svg :src="require(`../assets/svg/icon-list.svg`)" />
            </div>
        </div>
    </div>
</template>

<script>
    import { personasCollection } from '../firebase';
    import { mapGetters } from 'vuex';
    import InlineSvg from 'vue-inline-svg';
    import Markdown from 'vue3-markdown-it';
    import IconThumbnail from '../components/IconThumbnail.vue';
    import PageHeader from '../components/PageHeader.vue';
    import Badge from '../components/Badge.vue';
    import Slider from '../components/Slider.vue';

    export default {
        name: 'PersonaProfile',

        components: {
            InlineSvg,
            Markdown,
            IconThumbnail,
            PageHeader,
            Badge,
            Slider,
        },

        computed: {
            ...mapGetters({
                fetchPersona: 'personas/fetchPersona',
                projectsFiles: 'projects/projects',
                personaPublishedByProject: 'personas/personaPublishedByProject',
                getPersonaIndex: 'personas/getPersonaIndex',
            }),
            personas() {
                return this.fetchPersona
            },
            projects() {
                return this.projectsFiles
            },
            personasFromProject() {
                return this.personaPublishedByProject(this.$route.params.clientProjectNamePersonaPage);
            },
            personaIndex() {
                return this.getPersonaIndex(this.$route.params.clientProjectNamePersonaPage, this.$route.params.clientPersonaId);
            },
        },

        data() {
            return {
                // Images
                imageThumbnail: '',
                imageTopLeft: '',
                imageBottomLeft: '',
                imageMain: '',
                imageBottom: '',

                // Input / textarea fields
                name: '',
                description: '',
                tags: [
                    { icon: 'icon-heart', text: '', placeholder: 'Marital status' },
                    { icon: 'icon-briefcase', text: '', placeholder: 'Job' },
                    { icon: 'icon-book', text: '', placeholder: 'Education' },
                    { icon: 'icon-pin', text: '', placeholder: 'Location' },
                ],
                quote: '',
                keywords: ['', '', '', ''],
                story: '',
                typicalDay: '',
                sliders: [
                    { textLeft: '', textRight: '', value: '50' },
                    { textLeft: '', textRight: '', value: '50' },
                ],
                goals: '',
                obstacles: '',
                questions: '',
                tools: '',
                responsabilities: '',
                textComponents: [
                    { id: 0, icon: "icon-clipboard", title: "Goal and motivation", content: "" },
                    { id: 1, icon: "icon-flag", title: "Obstacles Persona faces", content: "" },
                    { id: 2, icon: "icon-info", title: "Tools Persona uses", content: "" },
                    { id: 3, icon: "icon-tool", title: "Goal and motivation", content: "" },
                    { id: 4, icon: "icon-briefcase", title: "Job responsabilities", content: "" },
                ],

                // Temporary data
                hasKeywords: false,
                hasSliders: false,
                hasTags: false,

                scrollspyTextComponents: [],
            }
        },

        methods: {
            openTocMobile() {
                const toc = document.getElementById('scrollspy-toc');
                toc.classList.toggle('show');
            }
        },

        // Populate fields
        created: function() {
            if(this.$route.params.clientPersonaId !== 'new') {
                personasCollection.doc(this.$route.params.clientPersonaId).get().then((persona) => {
                    this.name = persona.data().name;
                    this.description = persona.data().description;
                    this.quote = persona.data().quote;
                    this.tags = persona.data().tags;
                    this.keywords = persona.data().keywords;
                    this.story = persona.data().story;
                    this.typicalDay = persona.data().typicalDay;
                    this.sliders = persona.data().sliders;
                    this.textComponents = persona.data().textComponents;
                    this.imageThumbnail = persona.data().thumbnail;
                    this.imageTopLeft = persona.data().imageTopLeft;
                    this.imageBottomLeft = persona.data().imageBottomLeft;
                    this.imageMain = persona.data().imageMain;
                    this.imageBottom = persona.data().imageBottom;
                    this.isPublished = persona.data().isPublished;
                })
                .then(() => {
                    // Keywords
                    for(let i = 0; i < this.keywords.length; i++) {
                        if(this.keywords[i] !== '') {
                            this.hasKeywords = true;
                        }
                    }
                    // Sliders
                    for(let i = 0; i < this.sliders.length; i++) {
                        if(this.sliders[i].textLeft !== '') {
                            this.hasSliders = true;
                        }
                    }
                    // Tags
                    for(let i = 0; i < this.tags.length; i++) {
                        if(this.tags[i].text !== '') {
                            this.hasTags = true;
                        }
                    }

                    const leftSideHeight = document.querySelector('.side-elements--previous').offsetHeight;
                    console.log(leftSideHeight);
                    document.querySelector('.side-elements--next').style.height = leftSideHeight + 'px';

                    // Loading state
                    const loading = document.querySelector('.loading-state');
                    loading.classList.add('loaded');
                })
                .catch((error) => {
                    console.log(error);
                })
            }
        },

        watch: {
            "$route.params.clientProjectNamePersonaPage": {
                handler: function(value) {
                    if(value !== undefined) {
                        document.body.setAttribute('data-bs-spy', 'scroll');
                        document.body.setAttribute('data-bs-target', '#scrollspy-toc');
                        document.body.setAttribute('data-offset', '0');
                    }
                },
                deep: true,
                immediate: true,
            },
        }
    }
</script>
